import api from './api';

export async function createCredentials(): Promise<any | errors.APIError> {
  const response = await api.post<any, common.OTPAction | errors.APIError>(
    '/store/notificationChannelCredentials',
    {}
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}

export async function channelToken(context): Promise<any | errors.APIError> {
  const response = await api.post<any, common.OTPAction | errors.APIError>(
    '/store/notificationChannel',
    context,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}
