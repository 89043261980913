import { Box } from 'grommet';
import useAuthenticated from 'hooks/useAuthenticated';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MembersScreen } from 'screens';

import { addMember, allMembers, removeMember } from 'services/organization';

export default function MembersPage() {
  useAuthenticated();
  const [busy, setBusy] = useState(false);
  const [members, setMembers] = useState([]);

  async function fetchMembers() {
    if (busy) return;
    setBusy(true);
    try {
      const result = await allMembers();
      setMembers(result.data);
    } catch (error) {
      setBusy(false);
    }
    setBusy(false);
  }

  useEffect(() => {
    fetchMembers();
  }, []);

  const onAddMember = useCallback((member) => {
    if (busy) return;
    setBusy(true);
    async function run() {
      try {
        await toast.promise(addMember(member), {
          loading: 'Working...',
          success: <b>Member added</b>,
          error: (err) => <b>{err.toString()}</b>
        });
        fetchMembers();
      } catch (err) {
        setBusy(false);
      }
      setBusy(false);
    }
    run();
  }, []);

  const onRemoveMember = useCallback((member) => {
    if (busy) return;
    setBusy(true);
    async function run() {
      try {
        await toast.promise(removeMember(member), {
          loading: 'Working...',
          success: <b>Member removed</b>,
          error: (err) => <b>{err.toString()}</b>
        });
        fetchMembers();
      } catch (err) {
        setBusy(false);
      }
      setBusy(false);
    }
    run();
  }, []);

  return (
    <MembersScreen
      busy={busy}
      data={members}
      onAddMember={onAddMember}
      onRemoveMember={onRemoveMember}
    />
  );
}
