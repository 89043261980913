exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-logout-tsx": () => import("./../../../src/pages/auth/logout.tsx" /* webpackChunkName: "component---src-pages-auth-logout-tsx" */),
  "component---src-pages-auth-password-reset-tsx": () => import("./../../../src/pages/auth/password_reset.tsx" /* webpackChunkName: "component---src-pages-auth-password-reset-tsx" */),
  "component---src-pages-auth-phone-tsx": () => import("./../../../src/pages/auth/phone.tsx" /* webpackChunkName: "component---src-pages-auth-phone-tsx" */),
  "component---src-pages-auth-register-tsx": () => import("./../../../src/pages/auth/register.tsx" /* webpackChunkName: "component---src-pages-auth-register-tsx" */),
  "component---src-pages-auth-signin-tsx": () => import("./../../../src/pages/auth/signin.tsx" /* webpackChunkName: "component---src-pages-auth-signin-tsx" */),
  "component---src-pages-auth-verify-tsx": () => import("./../../../src/pages/auth/verify.tsx" /* webpackChunkName: "component---src-pages-auth-verify-tsx" */),
  "component---src-pages-business-edit-tsx": () => import("./../../../src/pages/business/edit.tsx" /* webpackChunkName: "component---src-pages-business-edit-tsx" */),
  "component---src-pages-business-members-tsx": () => import("./../../../src/pages/business/members.tsx" /* webpackChunkName: "component---src-pages-business-members-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-disperse-index-tsx": () => import("./../../../src/pages/disperse/index.tsx" /* webpackChunkName: "component---src-pages-disperse-index-tsx" */),
  "component---src-pages-engage-tsx": () => import("./../../../src/pages/engage.tsx" /* webpackChunkName: "component---src-pages-engage-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insight-tsx": () => import("./../../../src/pages/insight.tsx" /* webpackChunkName: "component---src-pages-insight-tsx" */),
  "component---src-pages-member-invite-tsx": () => import("./../../../src/pages/member/invite.tsx" /* webpackChunkName: "component---src-pages-member-invite-tsx" */),
  "component---src-pages-otp-index-tsx": () => import("./../../../src/pages/otp/index.tsx" /* webpackChunkName: "component---src-pages-otp-index-tsx" */),
  "component---src-pages-otp-verify-tsx": () => import("./../../../src/pages/otp/verify.tsx" /* webpackChunkName: "component---src-pages-otp-verify-tsx" */),
  "component---src-pages-payment-verify-tsx": () => import("./../../../src/pages/payment/verify.tsx" /* webpackChunkName: "component---src-pages-payment-verify-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-receive-tsx": () => import("./../../../src/pages/receive.tsx" /* webpackChunkName: "component---src-pages-receive-tsx" */),
  "component---src-pages-spend-tsx": () => import("./../../../src/pages/spend.tsx" /* webpackChunkName: "component---src-pages-spend-tsx" */),
  "component---src-pages-ssr-tsx": () => import("./../../../src/pages/ssr.tsx" /* webpackChunkName: "component---src-pages-ssr-tsx" */),
  "component---src-pages-start-business-tsx": () => import("./../../../src/pages/start/business.tsx" /* webpackChunkName: "component---src-pages-start-business-tsx" */),
  "component---src-pages-tools-sms-tsx": () => import("./../../../src/pages/tools/sms.tsx" /* webpackChunkName: "component---src-pages-tools-sms-tsx" */),
  "component---src-pages-tsx": () => import("./../../../src/pages/[...].tsx" /* webpackChunkName: "component---src-pages-tsx" */)
}

