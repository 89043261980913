import React, { useContext, useEffect, useState } from 'react';
import { ResponsiveContext, Box, List, Text, Spinner } from 'grommet';
import { StatusCritical, StatusGood, Deliver, Package } from 'grommet-icons';
import format from 'date-fns/format';
// import { useFilters } from '../FilterControls';
import { useFilters } from '@packages/design/components';
import { useModal } from '../../hooks/useModal';
import { SmsCard } from '../SmsCard';
import { EngageRunList } from '../EngageRunList';
import { engageRuns } from '../../services/engage';

export interface ActivityProps {}

const statusIcons = {
  failed: <StatusCritical />,
  done: <StatusGood color="status-ok" />,
  processing: <Deliver />,
  created: <Package />
};

function ActivityRows({ focusedData }) {
  const [accompaniedRows, setAccompaniedRows] = useState([]);
  const [busy, setbusy] = useState(false);

  useEffect(() => {
    if (!focusedData) {
      setAccompaniedRows([]);
      return;
    }
    async function run() {
      setbusy(true);
      try {
        const allruns = await engageRuns(focusedData.id);
        setAccompaniedRows(allruns.data);
      } catch (error) {
        alert(error.message);
      }
      setbusy(false);
    }
    run();
  }, [focusedData]);

  if (busy) {
    return (
      <Box fill align="center" justify="center">
        <Spinner />
      </Box>
    );
  }

  return <EngageRunList rows={focusedData ? accompaniedRows : []} />;
}

export function Activity({ children }: React.FC<ActivityProps>) {
  const size = useContext(ResponsiveContext);
  const { filteredResults } = useFilters();
  const [focusedData, setfocusedData] = useState(null);

  useEffect(() => {
    if (focusedData === null) return;
    show();
  }, [focusedData]);

  const { show, hide, RenderModal } = useModal(
    {
      title: 'SMS Report',
      onClose: () => {
        setfocusedData(null);
      }
    },
    'modal'
  );
  const onClick = (item) => (e) => {
    setfocusedData(item);
  };
  return (
    <>
      <div className="w-full overflow-x-auto">
        <table className="table w-full">
          {/* head */}
          <thead>
            <tr>
              <th>
                <label>
                  <input type="checkbox" className="checkbox" />
                </label>
              </th>
              {/* <th>Name</th> */}
              <th>Tool</th>
              <th>Title</th>
              <th>Date</th>
              <th>STatus</th>
            </tr>
          </thead>
          <tbody>
            {filteredResults.map((datum) => (
              <tr key={datum.id} onClick={onClick(datum)} className="cursor-pointer">
                <th>
                  <label>
                    <input type="checkbox" className="checkbox" />
                  </label>
                </th>
                {/* <td>
                  <Text color="text-strong" size="large" weight="bold" wordBreak="break-all">
                    {datum.name}
                  </Text>
                </td> */}
                <td>
                  <Text color="text-strong" size="small">
                    {datum.tool}
                  </Text>
                </td>
                <td>
                  <Text color="text-strong" weight="bold">
                    {datum.data.title}
                  </Text>
                </td>
                <td>
                  <Text color="dark-2" size="small">
                    {format(new Date(datum.created_at), 'MMM, dd, yyyy hh:mm a')}
                  </Text>
                </td>
                <td>
                  <div className="flex flex-row gap-2">
                    <Text color="text-strong">{datum.status}</Text>
                    {statusIcons[datum.status]}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <RenderModal>
        <div className="bg-base-200 flex w-full items-center justify-center py-10">
          <SmsCard {...focusedData?.data} />
        </div>
        <ActivityRows focusedData={focusedData} />
      </RenderModal>
    </>
  );
}
