import React, { useCallback, memo } from 'react';
import {
  Box,
  Button as GButton,
  ColumnConfig,
  DataTable,
  Heading,
  Page,
  PageContent,
  PageHeader,
  ResponsiveContext,
  Select,
  Spinner,
  Text
} from 'grommet';
import { Add } from 'grommet-icons';
import { useModal } from 'hooks/useModal';
import { InviteMemberForm } from 'components';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Button } from 'app/design/Button';
import Trash from 'app/icons/TrashIcon';
import { MemberInfoCell } from 'app/design/MemberItem/MemberInfoCell';
import { MemberList } from 'app/design/MemberList/MemberList';
import toast from 'react-hot-toast';

export interface MembersScreenProps {
  data: common.Member[];
  onAddMember: (member: common.Member) => void;
  onRemoveMember;
  busy?: boolean;
}
const MemoizedInviteMemberForm = memo(InviteMemberForm);
const ButtonText = styled.span(() => [tw`w-max`]);
export function PlainMembersScreen({
  busy,
  data,
  onAddMember,
  onRemoveMember
}: MembersScreenProps): JSX.Element {
  const { show, RenderModal } = useModal(
    {
      title: 'Add member',
      subtitle: 'Add new members in your business team'
    },
    'modal'
  );
  const addMember = useCallback(
    (member) => {
      onAddMember(member);
    },
    [onAddMember]
  );
  const onCopiedInviteURL = () => {
    toast.success('copied link to clipboard');
  };
  return (
    <>
      <Box pad={{ horizontal: 'large' }}>
        <PageContent>
          <PageHeader
            title={
              <Box direction="row" gap="small">
                <Heading level="2" margin="0">
                  Members
                </Heading>{' '}
                {busy && <Spinner />}
              </Box>
            }
            actions={
              <GButton
                title="Add Member"
                label={<ButtonText>Add member</ButtonText>}
                size="small"
                primary
                icon={<Add size="16" fill="white" />}
                onClick={show}
              />
            }
          />

          <MemberList
            data={data}
            onRemoveMember={onRemoveMember}
            onCopiedInviteURL={onCopiedInviteURL}
          />
        </PageContent>
      </Box>
      <RenderModal>
        <MemoizedInviteMemberForm onAddMember={addMember} data={data} />
      </RenderModal>
    </>
  );
}

PlainMembersScreen.defaultProps = {
  busy: false
};

export const MembersScreen = memo<MembersScreenProps>(PlainMembersScreen);
