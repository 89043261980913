import Centrifuge from 'centrifuge';
import { navigate } from 'gatsby';
import { Box, Form, Text, TextInput } from 'grommet';
import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useTimer } from 'use-timer';
import { OTPAction } from 'business/models/otp';
import { OtpFlow } from '../../components';
import Toast from '../../components/Toast';
import useAuthenticated from '../../hooks/useAuthenticated';
import useDigitInput from '../../hooks/useDigitInput';
import useEnsureWallet from '../../hooks/useEnsureWallet';
import { getOtp, verifyOtp } from '../../services/otp';
import { MessageListener, setupPush } from '../../utils/networking';

const verify = () => {
  let centrifuge: Centrifuge = null;
  useAuthenticated();
  useEnsureWallet();
  const [id] = useQueryParam('id', StringParam);
  const [loading, setLoading] = useState(false);
  const [otpaction, setOtpaction] = useState<OTPAction>(null);
  const [code, setCode] = React.useState('');
  const [notify, setNotify] = useState(false);
  const [notification, setNotification] = useState(':)');
  const [step, setStep] = useState(2);

  const ref = React.useRef(null);
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value: code,
    onChange: (v) => {
      setCode(v.trim());
    }
  });
  const { start } = useTimer({
    endTime: 5,
    onTimeOver: () => {
      navigate(`/${otpaction.origin}&msg=otp_verified`);
    }
  });
  // TODO: move to toast
  const showNotification = (message) => {
    setNotification(message);
    setNotify(true);
  };
  const hideNotification = () => {
    setNotification('');
    setNotify(false);
  };

  // send create otp action
  useEffect(() => {
    async function run() {
      if (loading) return;
      setLoading(true);
      showNotification('busy');
      try {
        const resp = await getOtp(id);
        if (resp.status === 'completed') {
          setStep(3);
        }
        setOtpaction(resp);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
      hideNotification();
    }
    run();
  }, [id]);

  async function sendCode(code) {
    if (loading) return;
    setLoading(true);
    hideNotification();
    ref?.current?.focus();
    try {
      await verifyOtp(id, code);
      navigate(`/${otpaction.origin}`);
    } catch (error: any) {
      showNotification(error.message);
    }
    setCode('');
    setLoading(false);
  }

  useEffect(() => {
    if (!loading && otpaction?.value?.phone && code.length === 6) {
      sendCode(code);
    }
  }, [loading, otpaction, code]);

  useEffect(() => {
    if (step === 3) {
      start();
    }
  }, [step]);

  useEffect(() => {
    async function run() {
      centrifuge = await setupPush(
        MessageListener(({ data }) => {
          if (data.h && data.method === 'phone' && data.status === 'completed') {
            setStep(3);
          }
        })
      );
    }
    run();
    return () => {
      if (centrifuge) centrifuge.disconnect();
    };
  }, []);

  const onCancel = () => {
    navigate(`/${otpaction.origin}&msg=otp_cancelled`);
  };
  return (
    <>
      <OtpFlow
        step={step}
        phone={otpaction?.value.phone}
        onCancel={onCancel}
        onDone={() => navigate(`/${otpaction.origin}&msg=otp_complete`)}
        form={
          step === 2 && (
            <Form>
              <Box
                gap="medium"
                pad={{ horizontal: 'medium', vertical: 'medium' }}
                background="light-1"
                round
                width="medium"
                margin={{ vertical: 'medium' }}>
                <Text weight="bold" size="small">
                  Or enter OTP code below
                </Text>
                <Box direction="row" gap="small" animation="fadeIn">
                  {digits.map((digit, k) => (
                    <TextInput
                      key={`${k}`}
                      width="100%"
                      inputMode="decimal"
                      autoFocus={!loading && k === 0}
                      disabled={loading}
                      {...digit}
                      {...(k === 0 ? { ref } : {})}
                    />
                  ))}
                </Box>
              </Box>
            </Form>
          )
        }
      />

      <Toast
        status="normal"
        visible={notify}
        onClose={() => setNotify(false)}
        title="Busy"
        message={notification}
      />
    </>
  );
};

export default verify;
