import React from 'react';
import { Box, Card, CardBody, CardFooter, Text, Button, Select, Paragraph } from 'grommet';
import { Info } from 'grommet-icons';
import { Link } from 'gatsby';
import { useAuth } from '../../context/auth';

export interface ActionCardProps {
  title?: string;
  description?: string;
  subtitle?: string;
  buttonTitle?: string;
  enabled?: boolean;
  icon?;
  to?: string;
  user?: { customers?: number };
}

export function ActionCard({
  title,
  subtitle,
  description,
  to,
  icon,
  enabled,
  buttonTitle
}: React.FC<ActionCardProps>) {
  const {
    state: { session }
  } = useAuth();
  return (
    <Card background="background-front" margin={{ right: 'medium' }}>
      <CardBody>
        <Identifier
          // icon={<Emoji size="large" color="text-strong" />}
          title={title}
          subtitle={subtitle}
        />
        <Box
          gap="xsmall"
          pad={{ horizontal: 'medium', top: 'medium' }}
          direction="row"
          width="medium"
          align="center">
          {icon || <Info />}
          <Paragraph size="small" color="dark-4">
            {description}
          </Paragraph>
          {/*
        <ContentBlock
          height={spacing.medium}
          width="100%"
          pad={{ horizontal: 'xsmall' }}
        >
          <Text>Flexible content area</Text>
        </ContentBlock>
        <ContentBlock height={spacing.medium} width="100%" />
        <ContentBlock height={spacing.medium} width="small" />
      */}
        </Box>
        {/* <Text size="medium" weight="bold">{user.customers} active customers</Text> */}
      </CardBody>
      <CardFooter align="center" gap="small">
        {enabled && (
          <Box direction="row" gap="medium" justify="between" align="center">
            <Select
              value="all"
              options={[{ value: 'all', label: `${session.data.customers} active customers` }]}
              labelKey="label"
              valueKey={{ key: 'value', reduce: true }}
            />
            <Button
              size="medium"
              as={Link}
              disabled={!enabled}
              label={buttonTitle}
              primary
              href={to}
            />
          </Box>
        )}
        {!enabled && (
          <Text weight="bold" color="dark-4" margin="medium">
            coming soon
          </Text>
        )}
      </CardFooter>
    </Card>
  );
}

ActionCard.defaultProps = {
  to: '/'
};

function Identifier({ title, subtitle, icon }) {
  return (
    <Box direction="row" gap="small" align="center">
      <Box pad={{ vertical: 'xsmall' }}>{icon}</Box>
      <Box>
        <Text color="text-strong" size="xlarge" weight="bolder">
          {title}
        </Text>
        <Text size="small">{subtitle}</Text>
      </Box>
    </Box>
  );
}

function ContentBlock({ background, height, width, ...rest }) {
  return <Box background={background} height={height} width={width} round="xxsmall" {...rest} />;
}

ContentBlock.defaultProps = {
  background: 'background-back'
};
