import { Button } from 'app/design/Button';
import { AutoBoxMinH } from 'components/AutoBoxMinH';
import { RangeInput } from 'components/RangeInput';
import { Form, Box, FormField, Spinner, Text, TextArea, TextInput, Heading, Footer } from 'grommet';
import { CircleAlert } from 'grommet-icons';
import React, { useCallback, useRef, useState } from 'react';
import tw from 'twin.macro';

const ModalForm = tw(Box)`px-5 overflow-scroll scrollbar-hide`;
export interface CreateWalletProps {
  onSubmit?: any;
  currency?: string;
  loading?: boolean;
  error?: string;
  wallet?: common.Wallet;
}
export function CreateWallet({ wallet, loading, onSubmit, error }: CreateWalletProps) {
  const form = useRef(null);
  const [value, setValue] = useState(wallet);
  const onLimit = (field, v) => {
    setValue({ ...value, [field]: v });
  };

  return (
    <ModalForm pad={{ horizontal: 'medium' }}>
      <Box pad="medium" flex overflow="auto">
        <Form
          ref={form}
          value={value}
          onSubmit={onSubmit}
          onChange={(nextValue) => {
            setValue(nextValue);
          }}
          onReset={() => setValue(wallet)}>
          <AutoBoxMinH>
            <FormField name="title" label="Title" htmlFor="title" required>
              <TextInput id="title" name="title" />
            </FormField>
            <FormField name="description" label="Description" htmlFor="description" required>
              <TextArea id="description" name="description" required />
            </FormField>
            {/* <Box margin={{ vertical: "medium" }}>
          <Text weight={"bold"} >Configuration</Text>
        </Box> */}
            {/* <Box pad={{ "vertical": "small" }} gap="small">
          <Box direction="row" gap="small" align="center">
            <Text size="small">Negative Balance</Text>
            <Box background="brand" width="50px" round="medium" alignSelf='center' pad="3px">
              <Text alignSelf="center" size="xsmall" weight="bold">{value.minimumBalance || 0}</Text>
            </Box>
          </Box>
          <Box direction="row" gap="medium">
            <Text weight={600}>-1K</Text>
            <RangeInput
              name="minimumBalance"
              min={-1000}
              max={0}
              step={100}
            />
            <Text weight={600}>0</Text>
          </Box>
        </Box> */}
            <Box margin={{ vertical: 'medium' }} gap="small">
              <Heading level="3" margin={{ bottom: 'small', top: '0' }}>
                Limits
              </Heading>
              <Box>
                <Text>Maximum transaction amount</Text>
                <RangeInput
                  min={0}
                  max={wallet.config?.max_transaction_amount ?? 1000}
                  step={100}
                  defaultValue={`${wallet.max_customer_credits || 100}`}
                  onChange={(v) => onLimit('max_customer_credits', v.target.value)}
                />
              </Box>
              <Box>
                <Text>Daily transactional value</Text>
                <RangeInput
                  min={0}
                  max={wallet.config?.daily.send ?? 10000}
                  step={100}
                  defaultValue={`${wallet.max_daily_credits || 5000}`}
                  onChange={(v) => onLimit('max_daily_credits', v.target.value)}
                />
              </Box>
            </Box>
            {error && (
              <Box
                margin={{ top: 'medium', bottom: 'medium' }}
                round="4px"
                pad="small"
                background="validation-critical"
                direction="row"
                gap="xsmall">
                <Box flex={false} margin={{ top: 'hair' }} pad={{ top: 'xxsmall' }}>
                  <CircleAlert size="small" />
                </Box>
                <Text size="xsmall">{error}</Text>
              </Box>
            )}
          </AutoBoxMinH>
          <div className="sticky flex items-center justify-center px-8 pb-10">
            <Button
              type="submit"
              label={wallet?.id ? 'Edit Wallet' : 'Add wallet'}
              loading={loading}
            />
          </div>
        </Form>
      </Box>
    </ModalForm>
  );
}

CreateWallet.defaultProps = {
  currency: 'NGN',
  onSubmit: () => {}
};
