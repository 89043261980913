import { Card, CardBody, Box, Paragraph, Text } from 'grommet';
import { UserExpert } from 'grommet-icons';
import React from 'react';
import tw from 'twin.macro';

export interface SmsCardProps {
  title?: string;
  body?: string;
}

const DummyText = tw.div`w-full bg-gray-100 rounded-md h-10`;
const DummyBody = tw.div`w-full bg-gray-100 rounded-md h-32`;

export function SmsCard({ title, body }: SmsCardProps) {
  return (
    <Card className="mx-5 w-full p-4">
      <CardBody pad="0">
        <div className="flex flex-row gap-3 px-2 py-4">
          {/* <Box pad="small" margin={{ right: "small" }} background="light-2" justify="center" align="center" round="medium" height="40px"> */}
          <div className="h-10 rounded-lg bg-gray-200 py-2 px-4">
            <UserExpert />
          </div>
          {/* </Box> */}
          <div className="flex flex-col gap-2">
            {title ? <Text weight="bold">{title}</Text> : <DummyText />}
            {body ? (
              <Paragraph style={{ whiteSpace: 'pre-line' }} weight="bold">
                {body}
              </Paragraph>
            ) : (
              <DummyBody />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
