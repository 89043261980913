import * as React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import Dashboard from 'screens/Dashboard';
import useAuthenticated from 'hooks/useAuthenticated';
import { allTransactions } from 'services/transaction';
import { OrganizationProvider, useOrganization } from 'context/organization';
import { ISession } from 'business/models/session';

function Home() {
  useAuthenticated();
  const { wallets, organization, loading: orgLoading, refresh } = useOrganization();
  const [session] = useLocalStorage<ISession>('vsn', {} as ISession);
  const [transactions, setTransactions] = React.useState<common.Transaction[]>([]);
  const [loading, setLoading] = React.useState(true);

  // TODO: screen is rerendering on fetch transactions
  // console.log({ orgLoading, wallets, organization });

  async function getTransactions(page = 0, count = 10) {
    setLoading(true);
    try {
      const resp = await allTransactions(page * count);
      setTransactions(resp);
    } catch (error) {
      // window.alert(error.message);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    if (organization) {
      getTransactions();
    }
  }, [organization]);

  // if (!session?.data) return <div />;

  return (
    <Dashboard
      session={session}
      transactions={transactions}
      wallets={wallets}
      loading={loading || orgLoading}
      getTransactions={getTransactions}
      refresh={refresh}
    />
  );
}

export default Home;
