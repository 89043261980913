import { PhoneInput } from 'app/design/PhoneInput';
import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  List,
  Menu,
  ResponsiveContext,
  Select,
  Text,
  TextInput
} from 'grommet';
import { More, Phone } from 'grommet-icons';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export interface InviteMemberFormProps {
  data?: common.Member[];
  onAddMember: (member: common.Member) => void;
}
const MemberListContainer = styled(Box)(() => [tw`max-h-96 overflow-scroll sm:pt-4`]);

function Action(item, index) {
  const { status } = item;
  return (
    <Box key={index} direction="row" align="center" gap="medium">
      <Box direction="row" gap="small" align="center">
        <Text>{status}</Text>
      </Box>
      <Menu icon={<More />} hoverIndicator items={[{ label: 'Remove' }]} />
    </Box>
  );
}

const PhoneFieldWrapper = tw(Box)`lg:w-[450px]`;

function PhoneField(props) {
  return (
    <PhoneFieldWrapper>
      <PhoneInput name="phone" onChange={(value) => props.onChange({ target: { value } })} />
    </PhoneFieldWrapper>
  );
}
export function InviteMemberForm({ data, onAddMember }: InviteMemberFormProps) {
  const [pendingMembers, setPendingMembers] = useState<common.Member[]>([...data]);
  const addMember = ({ value }) => {
    const member: common.Member = {
      role: 'teller',
      phone: value.phone.replace(/\s/g, '').replace('+', '')
    };
    setPendingMembers([member, ...pendingMembers]);
    onAddMember(member);
  };
  return (
    <Box pad={{ horizontal: 'medium', bottom: 'large' }} gap="medium" background="white">
      <Form onSubmit={addMember}>
        <Box
          direction="row-responsive"
          align="start"
          gap="small"
          pad={{ top: 'medium' }}
          width="100%">
          <FormField name="phone" component={PhoneField} />
          <FormField name="role" type="select">
            <Select name="role" size="small" options={['teller']} defaultValue="teller" required />
          </FormField>
          <Box margin={{ top: '5px' }} flex={false}>
            <Button type="submit" label="Add member" primary />
          </Box>
        </Box>
      </Form>
      <Heading level="4" margin="none">
        New Members
      </Heading>
      <MemberListContainer overflow="scroll">
        <List data={pendingMembers} action={Action}>
          {(datum: common.Member) => <InviteTile datum={datum} />}
        </List>
      </MemberListContainer>
    </Box>
  );
}

function InviteTile({ datum }: { datum: any }): React.ReactNode {
  return (
    <Box key={datum.phone} direction="row" gap="small" align="center">
      <Phone />
      <Text weight="bold">{datum.phone}</Text>
    </Box>
  );
}
InviteMemberForm.defaultProps = {
  data: []
};
