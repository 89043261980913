import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Paragraph,
  Button,
  Text,
  Pagination,
  Layer,
  PageHeader,
  ResponsiveContext
} from 'grommet';
import tw from 'twin.macro';
import { Close } from 'grommet-icons';
import { Link, navigate } from 'gatsby';
import { TransactionList } from 'app/design/transaction/TransactionList';
import { WithdrawModal } from 'app/design/WithdrawModal/WithdrawModal';
import { WalletTile } from 'app/design/WalletTile/WalletTile.web';
import { CreateWallet } from 'components/CreateWallet';
import { createWallet, editWallet } from 'services/wallet';
import { useModal } from 'hooks/useModal';
import { BuyCredit } from 'components/BuyCredit';
import { ISession } from 'business/models/session';
import { IActivity } from 'business/models/activity';
import User from 'business/models/user';
import { Loaders } from 'app/design/Loaders';
import { IWallet } from 'business/models/wallet';
import { useMutation } from '@tanstack/react-query';
import { createTransfer } from 'services/payment';
import { toast } from 'react-hot-toast';
import { useOrganization } from 'context/organization';

const sortWithModified = (array) =>
  array.sort((a, b) => {
    if ((a.modified_at || a.created_at) > (b.modified_at || b.created_at)) {
      return -1;
    }
    if ((a.modified_at || a.created_at) < (b.modified_at || b.created_at)) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });
const WalletContainer = tw.div`flex overflow-x-scroll pb-10 pt-8 scrollbar-hide w-full`;
const WalletInner = tw.div`flex flex-nowrap md:ml-11 ml-5`;

function WalletWithdrawModal({ wallet, onClose }: { wallet: IWallet }) {
  const newTransfer = useMutation({
    mutationFn: (data: { wallet: string; amount: string }) =>
      createTransfer(data.wallet, parseFloat(data.amount) * 100),
    onMutate: () => {
      toast.loading('loading');
    },
    onSuccess: () => {
      toast.dismiss();
      toast.success('withdrawal started');
      onClose();
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error?.message);
      onClose();
    }
  });
  return (
    <div className="flex flex-col gap-4 px-4 md:px-9">
      <span>{newTransfer?.error?.message}</span>
      <WithdrawModal
        wallet={wallet}
        onSubmit={newTransfer.mutate}
        loading={newTransfer.isLoading}
      />
    </div>
  );
}

interface DashboardProps {
  loading: boolean;
  session: ISession;
  transactions;
  refresh;
  wallets: common.Wallet[];
  getTransactions: (page: number, count?: number) => {};
}

export function Dashboard({
  loading,
  session,
  transactions: { data, total_count, count },
  wallets,
  refresh,
  getTransactions
}: DashboardProps) {
  const size = useContext(ResponsiveContext);
  const { organization } = useOrganization();
  const [createLoading, setLoading] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(null);
  const [page, setPage] = useState(1);
  const [error, setError] = useState('');
  const [focusedWallet, setFocusedWallet] = useState<IWallet>(null);
  const [withdrawWallet, setWithdrawWalet] = useState<IWallet>(null);
  const [focusedActivity, setFocusedActivity] = useState<IActivity>(null);
  const user = Object.assign(new User(), session?.data);

  const {
    show: showAddCreditModal,
    hide: hideAddCreditModal,
    RenderModal: AddCreditModal
  } = useModal(
    {
      title: 'Add Credit',
      onClose: () => {
        setFocusedWallet(null);
      }
    },
    'modal'
  );

  const {
    show: showActivityModal,
    // hide: hideActivityModal,
    RenderModal: ActivityModal
  } = useModal(
    {
      title: 'Activity',
      onClose: () => {
        setFocusedActivity(null);
      }
    },
    'modal'
  );

  const {
    show: showWithdrawModal,
    hide: hideWithdrawModal,
    RenderModal: RenderWithdrawModal
  } = useModal(
    {
      title: 'Withdraw',
      onClose: () => {
        setWithdrawWalet(null);
      }
    },
    'modal'
  );

  useEffect(() => {
    if (focusedWallet === null) return;
    showAddCreditModal();
  }, [focusedWallet]);

  useEffect(() => {
    if (withdrawWallet === null) return;
    showWithdrawModal();
  }, [showWithdrawModal, withdrawWallet]);

  const onPageChange = (event) => {
    setPage(event.page);
    getTransactions(event.page - 1, 10);
  };

  const onCreateWallet = async ({ value }) => {
    if (createLoading) return;
    setLoading(true);
    setError('');
    try {
      if (showWalletModal?.id) {
        await editWallet(showWalletModal?.id, value);
      } else {
        await createWallet(value);
      }
      setShowWalletModal(null);
      refresh();
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const onCreditBought = (resp) => {
    setFocusedWallet(null);
    hideAddCreditModal();
    navigate(resp.pull.data.authorization_url);
  };

  const onShowActivity = (activity) => {
    setFocusedActivity(activity);
    showActivityModal();
  };

  if (loading) return <Loaders.Dashboard />;
  return (
    <>
      <AddCreditModal>
        <BuyCredit wallet={focusedWallet} onCreditBought={onCreditBought} />
      </AddCreditModal>
      <ActivityModal>
        <div>
          <span>{focusedActivity?.id}</span>
        </div>
      </ActivityModal>
      <RenderWithdrawModal>
        <WalletWithdrawModal wallet={withdrawWallet} onClose={hideWithdrawModal} />
      </RenderWithdrawModal>
      {showWalletModal && (
        <Layer
          full={['xsmall', 'small'].includes(size) ? true : 'vertical'}
          responsive={false}
          position="right"
          onClickOutside={() => setShowWalletModal(null)}
          onEsc={() => setShowWalletModal(null)}>
          <Box
            direction="row"
            justify="between"
            align="center"
            pad={{ left: 'large', right: 'medium', top: 'medium' }}>
            <h2 tw="text-lg">{showWalletModal?.id ? 'Edit Wallet' : 'Add Wallet'}</h2>
            <Button icon={<Close />} onClick={() => setShowWalletModal(null)} />
          </Box>
          <CreateWallet
            onSubmit={onCreateWallet}
            loading={createLoading}
            wallet={showWalletModal}
            error={error}
          />
        </Layer>
      )}

      <Box>
        <Box
          align="start"
          justify="center"
          pad={{ bottom: 'small', horizontal: 'large' }}
          direction="column">
          <PageHeader
            pad="0"
            title={
              <div className="prose">
                <h1 className="m-0 p-0">
                  {`Hello, ${
                    session?.data?.profile?.firstName ||
                    session?.data?.firstName ||
                    session?.data?.name ||
                    session?.data?.verified_phone ||
                    ''
                  }`}
                </h1>
                <h2 className="m-0 my-2 mb-5 p-0">Welcome back 👋</h2>
              </div>
            }
            subtitle={
              organization?.storefront && (
                <a
                  target="_blank"
                  className="link link-success link-hover font-md"
                  href={`//${organization.storefront.domain}`}
                  rel="noreferrer">
                  View your store online{' '}
                </a>
              )
            }
            // actions={[]}
          />
        </Box>
        <Box align="start" justify="center" direction="column" margin={{ top: 'medium' }}>
          <Box direction="row" justify="between" fill="horizontal" pad={{ horizontal: 'large' }}>
            <Heading level="3" margin="0">
              My Wallets
            </Heading>
            {user.isOwner && (
              <Button
                className="link link-neutral no-underline"
                onClick={() => setShowWalletModal({})}>
                Add Wallet
              </Button>
            )}
          </Box>
          <Box pad={{ horizontal: 'large' }}>
            <Paragraph size="small" color="dark-4" fill={false} margin={{ bottom: '0' }}>
              See how much credit your customers have in your wallets.
            </Paragraph>
          </Box>
          {wallets?.length > 0 && (
            <WalletContainer>
              <WalletInner>
                {sortWithModified(wallets).map((wallet) => {
                  const { paid, received } = wallet.balance || {
                    paid: 0,
                    received: 0,
                    id: 'undefined'
                  };
                  const { title, owner, id } = wallet || {
                    title: '',
                    owner: ''
                  };
                  return (
                    <WalletTile
                      key={id}
                      Link={Link}
                      withActions
                      currency="NGN"
                      {...{
                        paid,
                        received,
                        id,
                        title,
                        owner
                      }}
                      isOwner={user.isOwner}
                      user={session?.data?.id}
                      onSend={() => navigate(`/disperse?id=${wallet.id}`)}
                      onReceive={() => navigate(`/receive?id=${wallet.id}`)}
                      onEdit={() => setShowWalletModal(wallet)}
                      onWithdraw={() => {
                        setWithdrawWalet(wallet);
                      }}
                      onAddCredit={() => {
                        if (user.isOwner) {
                          setFocusedWallet(wallet);
                        } else {
                          alert('would you like to notify the wallet owner to add credit');
                        }
                      }}
                    />
                  );
                })}
              </WalletInner>
            </WalletContainer>
          )}
        </Box>
        <Box pad={{ horizontal: 'large' }}>
          <Box
            direction="row-responsive"
            justify="between"
            align="center"
            margin={{ bottom: 'medium' }}>
            <Heading level="3">Activity</Heading>
            <Box align="center" direction="row-responsive" justify="between" />
            {total_count > 0 && (
              <Text>
                Showing {page === 1 ? 1 : page * count - 10} -{' '}
                {page * count > total_count ? total_count : page * count} of {total_count}
              </Text>
            )}
          </Box>
          <TransactionList
            transactions={data}
            loading={loading}
            session={session}
            onSelect={onShowActivity}
          />
          <Box align="end">
            <Pagination
              size="small"
              numberItems={total_count}
              page={page}
              background="brand"
              pad="medium"
              margin={{ top: 'small' }}
              round="small"
              onChange={onPageChange}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
