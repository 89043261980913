import { Centrifuge, Subscription } from 'centrifuge';
import { createCredentials, channelToken } from 'services/push';

interface MessageListenerType {
  onMessage?: (context: any) => void;
  onConnect?: (context: any) => void;
  onDisconnect: (dataset: any) => void;
}

export const MessageListener = (onMessage?: (arg0: any) => any, onConnect?, onDisconnect?) => ({
  onConnect(ctx) {
    if (onConnect) onConnect(ctx);
  },
  onSubscribed(ctx) {},
  onDisconnect(ctx) {
    if (onDisconnect) onDisconnect(ctx);
  },
  onMessage: (ctx) => {
    if (onMessage) onMessage(ctx);
  }
});

export async function setupPush(listener: MessageListenerType) {
  let client: Centrifuge = null;
  let subscription: Subscription = null;
  const credentials = await createCredentials();
  const { channel, _realtime_token: token } = credentials;
  const clientID = '';
  client = new Centrifuge(`wss://${process.env.GATSBY_REALTIME}/connection/websocket`, {
    debug: true,
    token
  });
  subscription = client.newSubscription(channel, {
    getToken(ctx) {
      return channelToken({ channels: [ctx.channel], client: clientID }).then(
        (rsp) => rsp.channels[0].token
      );
    }
  });
  subscription.on('subscribing', function (ctx) {
    // console.log('subscribing', ctx);
  });

  subscription.on('subscribed', function (ctx) {
    listener.onConnect(ctx);
  });

  subscription.on('unsubscribed', function (ctx) {
    console.log('unsubscribed', ctx);
  });

  subscription.on('publication', function (ctx) {
    listener.onMessage(ctx);
  });
  subscription.subscribe();

  client.on('connecting', (ctx) => {
    // console.log('connecting', ctx);
  });
  client.on('connected', (ctx) => {
    listener.onConnect(ctx);
  });
  client.on('disconnected', (ctx) => {
    listener.onDisconnect(ctx);
  });
  client.on('error', (err) => {
    // console.error(err);
  });
  client.connect();
  return client;
}
